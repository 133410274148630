import { render, staticRenderFns } from "./PageDescription.vue?vue&type=template&id=f4de51ae&"
import script from "./PageDescription.vue?vue&type=script&lang=js&"
export * from "./PageDescription.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports